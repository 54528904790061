<template>
  <div class="auth-wrapper auth-v2 text-din-regular">
    <b-row
      class="auth-inner m-0 d-flex align-items-bottom"
      style="height:100vh; background-color: #ffffff;"
    >
      <!-- Left Text-->
      <b-col
        v-if="validFedId || validFedId === null"
        lg="8"
        class="d-none d-lg-flex align-items-center p-5 "
        style="height: 95%;"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <!-- :src="imgUrl" -->
          <b-img
            src="@/assets/images/dsg_career_portal.png"
            class="auth-image"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <LoginForm v-if="validFedId || validFedId === null" />
      <div
        v-else
        class="mx-auto"
      >
        <b-alert
          variant="danger"
          show
          class="p-2 mx-auto mt-4"
        >
          Your authentication link is invalid or has expired.
        </b-alert>
      </div>
      <!-- /Login-->

      <div
        class="w-100"
        style="height: 3%; margin-inline:0.5em;"
      >
        <AppFooter
          class="m-0 p-0 w-100"
          style="height: 2em;"
        />
      </div>
    </b-row>

  </div>
</template>

<script>
/* eslint-disable global-require */

import { BRow, BCol, BImg } from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import AppFooter from '@/layouts/components/AppFooter.vue'
import LoginForm from './LoginForm.vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    AppFooter,
    LoginForm,
  },
  mixins: [togglePasswordVisibility],
  props: {

    validFedId: { type: Boolean, default: null },
  },
  data() {
    return {
      sideImg: `${this.$bucketUrl}/media/login.svg`,
    }
  },
  computed: {
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = `${this.$bucketUrl}/media/login-v2-dark.svg`
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
